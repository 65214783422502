import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "../../assets/css/newsevents.css";
import Newsletter from "../NewsLetter";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fabullhorn } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const articlesData = [
  {
    id: 1,
    title:
      "Cync Software Launches Advanced Securities-Based Lending Solution for Financial Institutions",
    date: "February 05, 2025 \u00A0 \u00A0 | \u00A0 \u00A0 Press Release \u00A0 \u00A0 ",
    imageUrl: "/images/icbanews.jpg",
    content:
      "Cync Securities-Based Lending enables financial institutions to swiftly lend against a diverse range of domestically and internationally traded marketable securities, including stocks, funds, and bonds...",
    buttonLabel: "Read More",
    buttonLink:
      "https://www.prweb.com/releases/cync-software-launches-advanced-securities-based-lending-solution-for-financial-institutions-302368683.html",
  },
  {
    id: 2,
    title:
      "Cync Software Launches a Highly Flexible, Automated Solution for Simplifying Syndicated Loan Management",
    date: "August 27, 2024 \u00A0 \u00A0 | \u00A0 \u00A0 Press Release \u00A0 \u00A0 ",
    imageUrl: "/images/syndicatenews.jpg",
    content:
      "Cync Software, a leading provider of cutting-edge lending solutions to banks, credit unions, and other financial institutions, today announced the launch...",
    buttonLabel: "Read More",
    buttonLink:
      "https://www.prweb.com/releases/cync-software-launches-a-highly-flexible-automated-solution-for-simplifying-syndicated-loan-management-302232588.html",
  },
  // Add more articles as needed
];

const eventsData = [
  {
    id: 1,
    title: "Shaping the Future of Risk Management",
    date: "April 24-25, 2025\u00A0 \u00A0|\u00A0 \u00A0 Omni Hotel Charlotte, NC",
    imageUrl: "/images/rma-events.jpg",
    description:
      "Join Cync Software at the RMA Carolinas-Virginias Spring Conference to discuss about the latest innovations in lending and how can Cync enhance your operations...",
    websiteLabel: "RMA  Website",
    websiteLink:
    "https://carolinasvirginias.afrogs.org/#/index",
  },
  {
    id: 2,
    title: "ICBA LIVE 2025",
    date: "March 11-14, 2025\u00A0 \u00A0|\u00A0 \u00A0 Nashville, Tennessee",
    imageUrl: "/images/icba.jpg",
    description:
      "Join Cync Software at ICBA LIVE 2025! Stop by Booth #347 to discover the latest innovations in lending...",
  },
  {
    id: 3,
    title: "SFNet's 80th Annual Convention",
    date: "November 13-15, 2024\u00A0 \u00A0|\u00A0 \u00A0Houston, TX",
    imageUrl: "/images/SFNet.jpg",
    description:
      "Cync Software is thrilled to be at SFNet's 80th Annual Convention! Stop by our booth, meet our team, and discover the latest innovations in lending...",
  },
  {
    id: 4,
    title: "Money20/20 USA",
    date: "October 27-30, 2024\u00A0 \u00A0|\u00A0 \u00A0The Venetian Resort\u00A0 \u00A0|\u00A0 \u00A0Las Vegas",
    imageUrl: "/images/money2020.jpg",
    description:
      "Cync Software is thrilled to be at Money20/20 USA! Stop by our booth, meet our team, and discover the latest innovations in lending...",
  },
  {
    id: 5,
    title: "FinovateFall 2024",
    date: "September 9-11, 2024\u00A0 \u00A0|\u00A0 \u00A0The Marriott Marquis Times Square\u00A0 \u00A0|\u00A0 \u00A0New York, NY",
    imageUrl: "/images/finologo.jpg",
    description:
      "Cync Software is thrilled to be at FinovateFall 2024! If you're here to discover the latest innovations in lending, make Booth 206 your first stop...",
  },
  {
    id: 6,
    title:
      "Risk Management Association’s (RMA) 2024 Chapter Leaders Conference",
    date: "June 26-28, 2024  \u00A0 \u00A0 | \u00A0 \u00A0 Embassy Suites \u00A0 \u00A0 | \u00A0 \u00A0 Nashville, Tennessee",
    imageUrl: "/images/event3.png",
    description:
      "Join Cync Software at RMA’s 2024 Chapter Leaders Conference to discuss fresh perspectives on mitigating risk through automation!",
  },

  {
    id: 7,
    title: "ABA Risk & Compliance Conference",
    date: "June 11-14, 2024 \u00A0 \u00A0 | \u00A0 \u00A0 Seattle Convention Cente \u00A0 \u00A0 | \u00A0 \u00A0 Seattle, Washington",
    imageUrl: "/images/aba.png",
    description:
      "Join Cync Software at The American Bankers Association Risk & Compliance Conference! This premier event for risk and compliance professionals assembles the nation’s top experts and practitioners to offer you the latest solutions for operational challenges and increasingly interconnected and complex bank threats.",
  },

  {
    id: 8,
    title: "Financial Brand Forum",
    date: "May 20-22, 2024  \u00A0 \u00A0 | \u00A0 \u00A0 Aria Hotel & Resort  \u00A0 \u00A0 | \u00A0 \u00A0 Las Vegas, Nevada ",
    imageUrl: "/images/event2.png",
    description:
      "Join Cync Software at The Financial Brand Forum featuring over 2,500 senior executives at the biggest conference on marketing, CX, data analytics, AI, and digital growth strategies in the banking world!",
  },
  {
    id: 9,
    title: "30th Annual Factoring Conference",
    date: "May 1-3, 2024 \u00A0 \u00A0 | \u00A0 \u00A0 Fontainebleau Miami Beach \u00A0 \u00A0 | \u00A0 \u00A0 Miami Beach, Florida ",
    imageUrl: "/images/event1.png",
    description:
      "Join Cync Software at the 30th Annual Factoring Convention to see how we can automate your Factoring operations!",
    // buttonLabel: "Read  More",
    // buttonLink: "#",
    // websiteLabel: "IFA  Website",
    // websiteLink:
    // "https://www.sfnet.com/home/events/event-detail/2024/11/13/default-calendar/sfnets-80th-annual-convention",
  },
  // Add more events as needed
];

const NewsEventsPage = () => {
  const [activeTab, setActiveTab] = useState("events");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Helmet>
        <title>News and Events - Cync Software®</title>
        <meta
          name="description"
          content="Explore cutting-edge technology trends and innovations at our upcoming industry-leading events and conferences."
        />
      </Helmet>

      <motion.div
        className="Productright"
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <section className="news-sections">
          <Container className="h-100">
            <Row className="h-100 align-items-center">
              <Col lg={9}>
                <div className="content">
                  <motion.div
                    className="Productleft"
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true, amount: 0.7 }}
                  >
                    <h1>News & Events</h1>
                  </motion.div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>

      <Newsletter />

      <section className="tabs">
        <Nav variant="tabs" defaultActiveKey="news">
          <Nav.Item>
            <Nav.Link
              eventKey="news"
              onClick={() => handleTabChange("news")}
              active={activeTab === "news"}
              className={activeTab === "news" ? "active-tab" : ""}
            >
              <FontAwesomeIcon icon="fa-solid fa-bullhorn" />
              News
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="events"
              onClick={() => handleTabChange("events")}
              active={activeTab === "events"}
              className={activeTab === "events" ? "active-tab" : ""}
            >
              Events
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="news" active={activeTab === "news"}>
            <div className="articles">
              {articlesData.map((article) => (
                <div key={article.id} className="article">
                  <div className="left">
                    <img src={article.imageUrl} alt={article.title} />
                  </div>
                  <div className="right">
                    <h1>{article.title}</h1>
                    <p style={{ color: "#E93740", fontWeight: "bold" }}>
                      {article.date}
                    </p>
                    <p>{article.content}</p>
                    <Button size="small" color="primary">
                      <a href={article.buttonLink} target="_blank">
                        {article.buttonLabel}
                      </a>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="events" active={activeTab === "events"}>
            <div className="events">
              {eventsData.map((event) => (
                <div key={event.id} className="event">
                  <div className="left">
                    <img src={event.imageUrl} alt={event.title} />
                  </div>
                  <div className="right">
                    <h1>{event.title}</h1>
                    <p style={{ color: "#E93740", fontWeight: "bold" }}>
                      {event.date}
                    </p>
                    <p>{event.description}</p>
                    {/* Only render button if event.buttonLabel exists */}
                    {event.buttonLabel && event.buttonLink && (
                      <Button size="small" color="primary">
                        <a
                          href={event.buttonLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.buttonLabel}
                        </a>
                      </Button>
                    )}
                    <a
                      target="_blank"
                      className="externallink"
                      href={event.websiteLink}
                    >
                      {event.websiteLabel}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </section>
    </div>
  );
};

export default NewsEventsPage;
