import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/Popup.css";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import emailjs from "emailjs-com";

// Custom validation function to check for official email domains
const excludedEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "yahoomail.com",
  "outlook.com",
  "aol.com",
  "protonmail.com",
  "zohomail.com",
  "gmx.com",
  "icloud.com",
  "yandex.com",
  "comcast.com",
]; // Add your excluded domains here
const isOfficialEmail = (email) => {
  const domain = email.split("@")[1];
  return !excludedEmailDomains.includes(domain);
};

const urlPattern = /(https?:\/\/[^\s]+)/g; // Matches http:// or https:// URLs
const htmlTagPattern = /<\/?[^>]+(>|$)/g; // Matches any HTML tags

// Validation Schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("First Name is Required"),
  lastName: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Last Name is Required"),
  company: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Company Name is Required"),
  jobTitle: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Job Title is Required"),
  email: Yup.string()
    .email("Invalid email format")
    .test("is-official", "Please use an official email", (value) =>
      isOfficialEmail(value)
    )
    .required("Business Email is required"),
  phone: Yup.string().matches(
    /^[0-9,()-]+$/,
    "Phone number must contain only digits"
  ),
  products: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Please select any one of the product"),
  message: Yup.string().matches(/^[^<>]+$/, "Invalid input"), // Prevents angle brackets
  // .matches(urlPattern, "URLs are not allowed") // Prevents URLs
  // .matches(htmlTagPattern, "HTML tags are not allowed") // Prevents HTML tags
  // .required("Message is required"),
  agree: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

const CircleupPopup = ({ show, handleClose }) => {

   const firstInputRef = useRef(null);
  
   useEffect(() => {
    if (show && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [show]);  // Use 'show' instead of 'isOpen'
  


  const initialValues = {
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    phone: "",
    products: "Cync ABL",
    message: "",
    agree: false,
  };

  const [formData, setFormData] = useState(initialValues);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    navigate("/customer-stories/circleup/thank-you");
    resetForm();
    try {
      const apiUrl = `${process.env.REACT_APP_NEWSLETTER_API_URL}/circleup-email`;

      // Fetch user's IP address
      const ipResponse = await fetch("https://api64.ipify.org?format=json");
      const ipData = await ipResponse.json();
      const userIp = ipData.ip; // Extract IP address

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
        body: JSON.stringify({
          to: ["Cync-Website-Lead@cyncsoftware.com"], // Receiver's email address
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company,
          jobTitle: values.jobTitle,
          email: values.email,
          phone: values.phone,
          products: values.products,
          message: values.message,
          ip: userIp,
        }),
      });

      // if (response.ok) {
      //    // setFormSubmitted(true);
      //    navigate("/customer-stories/circleup/thank-you");
      //    setSubmissionSuccess(true);

      //   resetForm();
      // } else {
      //   console.error("Error sending email");
      // }
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  useEffect(() => {
    if (submissionSuccess) {
      const timer = setTimeout(() => {
        setSubmissionSuccess(false);
        handleClose();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submissionSuccess, handleClose]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      className="formpopup"
    >
      <Modal.Header closeButton>
        <Modal.Title>Achieve Similar Results</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {submissionSuccess ? (
          <div className="thank-you-message">
            <h3>Thank you!</h3>
            <p>Your request has been submitted successfully.</p>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, isValid, dirty }) => (
              <FormikForm className="contact-form-container">
                <Row>
                  <Col md={4} className="contact-row">
                    <Form.Group controlId="firstName">
                      <Field
                        className="form-control contact-input"
                        innerRef={firstInputRef}
                        type="text"
                        name="firstName"
                        placeholder="First Name*"
                        value={values.firstName}
                        onChange={handleChange}
                        required
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="contact-row">
                    <Form.Group controlId="lastName">
                      <Field
                        className="form-control contact-input"
                        type="text"
                        name="lastName"
                        placeholder="Last Name*"
                        value={values.lastName}
                        onChange={handleChange}
                        required
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="contact-row">
                    <Form.Group controlId="company">
                      <Field
                        className="form-control contact-input"
                        type="text"
                        name="company"
                        placeholder="Company Name*"
                        value={values.company}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="company"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="contact-row">
                    <Form.Group controlId="jobTitle">
                      <Field
                        className="form-control contact-input"
                        type="text"
                        name="jobTitle"
                        placeholder="Job Title*"
                        value={values.jobTitle}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="jobTitle"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="contact-row">
                    <Form.Group controlId="email">
                      <Field
                        className="form-control contact-input"
                        type="email"
                        name="email"
                        placeholder="Business Email*"
                        value={values.email}
                        onChange={handleChange}
                        required
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="contact-row">
                    <Form.Group controlId="phone">
                      <Field
                        className="form-control contact-input"
                        type="tel"
                        name="phone"
                        placeholder="Phone Number (Optional)"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group
                  controlId="products"
                  className="contact-rows"
                  style={{ position: "relative" }}
                >
                  <Field
                    className="form-control contact-input"
                    as="select"
                    name="products"
                    value={values.products}
                    onChange={handleChange}
                    required
                    style={{ appearance: "none", paddingRight: "30px" }} // Ensure enough space for the icon
                  >
                    <option value="">Select Product</option>
                    <option value="Cync LOS">Cync LOS</option>
                    <option value="Cync Spreading">Cync Spreading</option>
                    <option value="Cync ABL">Cync ABL</option>
                    <option value="Cync SBL">Cync SBL</option>
                    <option value="Cync ABL">Cync Syndicated Lending</option>
                    <option value="Cync Factoring">Cync Factoring</option>
                  </Field>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    className="custom-select-icon"
                  />
                  <ErrorMessage
                    name="products"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group controlId="message" className="contact-rows">
                  <Field
                    className="form-control contact-input"
                    as="textarea"
                    rows={3}
                    name="message"
                    placeholder="Message"
                    value={values.message}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group controlId="agree" className="contact-rows">
                  <Field
                    type="checkbox"
                    className="form-check-input"
                    name="agree"
                    checked={values.agree}
                    onChange={handleChange}
                    required
                  />
                  <label
                    className="form-check-label"
                    style={{
                      marginLeft: "25px",
                      top: "-24px",
                      position: "relative",
                      color: "black",
                    }}
                  >
                    I confirm that I have read and agree to{" "}
                    <a href="/privacy-policy" target="_blank">
                      Cync Software's Privacy Policy
                    </a>{" "}
                    and consent to the sharing of my information.
                  </label>
                  <ErrorMessage
                    name="agree"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Button
                  className="formbtn"
                  variant="primary"
                  type="submit"
                  disabled={!(dirty && isValid)}
                  style={{ width: "100%", backgroundColor: "#152C5B" }}
                >
                  Submit
                </Button>
              </FormikForm>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CircleupPopup;
