import React, { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import "../../assets/css/RequestDemo.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect } from "react";

// Custom validation function to check for official email domains
const excludedEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "yahoomail.com",
  "outlook.com",
  "aol.com",
  "protonmail.com",
  "zohomail.com",
  "gmx.com",
  "icloud.com",
  "yandex.com",
  "comcast.com",
]; // Add your excluded domains here
const isOfficialEmail = (email) => {
  const domain = email.split("@")[1];
  return !excludedEmailDomains.includes(domain);
};

const urlPattern = /(https?:\/\/[^\s]+)/g; // Matches http:// or https:// URLs
const htmlTagPattern = /<\/?[^>]+(>|$)/g; // Matches any HTML tags

// Validation Schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("First Name is Required"),
  lastName: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Last Name is Required"),
  company: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Company Name is Required"),
  jobTitle: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Job Title is Required"),
  email: Yup.string()
    .email("Invalid email format")
    .test("is-official", "Please use an official email", (value) =>
      isOfficialEmail(value)
    )
    .required("Business Email is Required"),
  phone: Yup.string().matches(
    /^[0-9,()-]+$/,
    "Phone number must contain only digits"
  ),
  products: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Please select any one of the product"),

  message: Yup.string().matches(/^[^<>]+$/, "Invalid input"), // Prevents angle brackets
  // .matches(urlPattern, "URLs are not allowed") // Prevents URLs
  // .matches(htmlTagPattern, "HTML tags are not allowed") // Prevents HTML tags
  // .required("Message is required"),

  agree: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

const RequestDemo = () => {

  const firstInputRef = useRef(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus(); // Focus on the first input field
    }
  }, []);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    phone: "",
    products: "",
    message: "",
    agree: false,
  };

  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    navigate("/contact/thank-you");
    resetForm();
    try {
      const apiUrl = `${process.env.REACT_APP_NEWSLETTER_API_URL}/demo-email`;

      // Fetch user's IP address
      const ipResponse = await fetch("https://api64.ipify.org?format=json");
      const ipData = await ipResponse.json();
      const userIp = ipData.ip; // Extract IP address

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
        body: JSON.stringify({
          to: ["Cync-Website-Lead@cyncsoftware.com"], // Receiver's email address
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company,
          jobTitle: values.jobTitle,
          email: values.email,
          phone: values.phone,
          products: values.products,
          message: values.message,
          ip: userIp,
        }),
      });

      // if (response.ok) {
      //   setFormSubmitted(true);
      //   navigate("/contact/thank-you");
      //   resetForm();
      // } else {
      //   console.error("Error sending email");
      // }
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  const verifyCallback = (recaptchaToken) => {
    console.log("reCAPTCHA token:", recaptchaToken);
    return recaptchaToken;
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdKfxgjAAAAAPO0jJTu6rbM0q8X8agpHUjBDsiA">
      <div>
        <Helmet>
          <title>Schedule a Demo With Lending Experts - Cync Software®</title>
          <meta
            name="description"
            content="Schedule a Demo - A Cync Software expert will guide you through the key features and benefits of your chosen product."
          />
        </Helmet>

        <motion.div
          className="Productright"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <section className="demo-sections">
            <Container className="h-100">
              <Row className="h-100 align-items-center">
                <Col lg={9}>
                  <div className="content">
                    <motion.div
                      className="Productleft"
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8, ease: "easeOut" }}
                      viewport={{ once: true, amount: 0.7 }}
                    >
                      <h1>Schedule a Demo</h1>
                    </motion.div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </motion.div>

        <div className="space"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contact-box" style={{ padding: "15px" }}>
                <h1>What to Expect on Your Demo</h1> <br />
                <p className="contentpara">
                  A dedicated Cync Software expert will guide you through the
                  key features and benefits for the product of your choice.
                </p>
                <p className="contentpara">
                  We’ll discuss your specific use cases and demonstrate how our
                  intelligent solutions can streamline your lending operations.
                </p>
                <p className="contentpara">
                  You’ll have the chance to ask questions and receive
                  personalized recommendations to supercharge your lending
                  automation.
                </p>
                <br /> <br />
              </div>
              {/* <div style={{ padding: "15px", background: "#F7FAFF" }}>
                <p className="contentpara">
                  For a head start, download our Product Brochure below. It
                  offers a quick preview of Cync Software’s products and sets
                  the stage for an even more insightful demo.
                </p>
                <a href="https://cyncsoftware.com/product-brochure/cync-brochure.pdf" target="_blank">
                <span style={{ display: "flex" }}>
                  <p
                    className="contentpara"
                    style={{
                      marginRight: "10px",
                      color: "#007bff",
                      fontWeight: "bold",
                    }}
                  >
                    [Download Product Brochure]
                  </p>
                  <img
                    style={{
                      width: "20px",
                      height: "25px",
                      top: "5px",
                      position: "relative",
                    }}
                    src={pdf}
                  />
                </span>
                </a>
              </div> */}
            </div>
            <div className="col-md-1"></div>

            <div className="col-md-5 form-container">
              {formSubmitted ? (
                <div className="thank-you-message">
                  <h2>Thank You!</h2>
                  <p>
                    Your message has been sent successfully. We will get back to
                    you soon.
                  </p>
                </div>
              ) : (
                <>
                  <h2>Request a Demo </h2>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                      const token = await verifyCallback(); // Get the reCAPTCHA token
                      handleSubmit(
                        { ...values, recaptchaToken: token },
                        { resetForm }
                      );
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      isValid,
                      dirty,
                    }) => (
                      <Form
                        onSubmit={handleSubmit}
                        className="contact-form-container"
                      >
                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="firstName">
                              <Field
                                className="form-control contact-input"
                                innerRef={firstInputRef}
                                type="text"
                                name="firstName"
                                placeholder="First Name*"
                                value={values.firstName}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="lastName">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="lastName"
                                placeholder="Last Name*"
                                value={values.lastName}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="company">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="company"
                                placeholder="Company Name*"
                                value={values.company}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="company"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="jobTitle">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="jobTitle"
                                placeholder="Job Title*"
                                value={values.jobTitle}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="jobTitle"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="email">
                              <Field
                                className="form-control contact-input"
                                type="email"
                                name="email"
                                placeholder="Business Email*"
                                value={values.email}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="phone">
                              <Field
                                className="form-control contact-input"
                                type="tel"
                                name="phone"
                                placeholder="Phone Number (Optional)"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group
                          controlId="products"
                          className="contact-rows"
                          style={{ position: "relative" }}
                        >
                          <Field
                            className="form-control contact-input"
                            as="select"
                            name="products"
                            value={values.products}
                            onChange={handleChange}
                            required
                            style={{ appearance: "none", paddingRight: "30px" }} // Ensure enough space for the icon
                          >
                            <option value="">Select Product</option>
                            <option value="Cync LOS">Cync LOS</option>
                            <option value="Cync Spreading">
                              Cync Spreading
                            </option>
                            <option value="Cync ABL">Cync ABL</option>
                            <option value="Cync SBL">Cync SBL</option>
                            <option value="Cync Syndicated Lending">
                              Cync Syndicated Lending
                            </option>

                            <option value="Cync Factoring">
                              Cync Factoring
                            </option>
                          </Field>
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="custom-select-icon"
                          />
                          <ErrorMessage
                            name="products"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="message"
                          className="contact-rows"
                        >
                          <Field
                            className="form-control contact-input"
                            as="textarea"
                            rows={3}
                            name="message"
                            placeholder="Message"
                            value={values.message}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>

                        <Form.Group controlId="agree" className="contact-rows">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            name="agree"
                            checked={values.agree}
                            onChange={handleChange}
                            required
                          />
                          <label
                            className="form-check-label"
                            style={{
                              marginLeft: "25px",
                              top: "-24px",
                              position: "relative",
                            }}
                          >
                            I confirm that I have read and agree to{" "}
                            <a href="/privacy-policy" target="_blank">
                              Cync Software's Privacy Policy
                            </a>{" "}
                            and consent to the sharing of my information.
                          </label>
                          <ErrorMessage
                            name="agree"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>

                        <Button
                          className="formbtn"
                          variant="primary"
                          type="submit"
                          disabled={!(dirty && isValid)}
                        >
                          Submit
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="space"></div>
        <div className="space"></div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default RequestDemo;
