import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "../../assets/css/Contactform.css";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

// Custom validation function to check for official email domains
const excludedEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "yahoomail.com",
  "outlook.com",
  "aol.com",
  "protonmail.com",
  "zohomail.com",
  "gmx.com",
  "icloud.com",
  "yandex.com",
  "comcast.com",
];
const isOfficialEmail = (email) => {
  const domain = email.split("@")[1];
  return !excludedEmailDomains.includes(domain);
};


// Validation Schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("First Name is Required"),
  lastName: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Last Name is Required"),
  company: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Company is Required"),
  jobTitle: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Job Title is Required"),
  email: Yup.string()
    .email("Invalid email format")
    .test("is-official", "Please use an official email", (value) =>
      isOfficialEmail(value)
    )
    .required("Business Email is Required"),
  phone: Yup.string().matches(
    /^[0-9,()-]+$/,
    "Phone number must contain only digits"
  ),
  inquiry: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Inquiry type is required"),
  message: Yup.string().matches(/^[^<>]+$/, "Invalid input"),
  // .matches(urlPattern, "URLs are not allowed")
  // .matches(htmlTagPattern, "HTML tags are not allowed")
  // .required("Message is required"),
  agree: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

const ContactForm = () => {

    const firstInputRef = useRef(null);
  
    useEffect(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input field
      }
    }, []);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    phone: "",
    inquiry: "",
    message: "",
    agree: false,
  };

  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    navigate("/contact/thank-you");
    resetForm();
    try {
      const apiUrl = `${process.env.REACT_APP_NEWSLETTER_API_URL}/contact-email`;
      // Fetch user's IP address
      const ipResponse = await fetch("https://api64.ipify.org?format=json");
      const ipData = await ipResponse.json();
      const userIp = ipData.ip; // Extract IP address
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
        body: JSON.stringify({
          to: ["Cync-Website-Lead@cyncsoftware.com"], // Receiver's email address
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company,
          jobTitle: values.jobTitle,
          email: values.email,
          phone: values.phone,
          inquiry: values.inquiry,
          message: values.message,
          ip: userIp,
        }),
      });

      // if (response.ok) {
      //   navigate("/contact/thank-you");
      //   setFormSubmitted(true);
      //   resetForm();
      // } else {
      //   console.error("Error sending email");
      // }
    } catch (error) {
      console.error("Error sending email", error);
    }
  };


  const verifyCallback = (recaptchaToken) => {
    console.log("reCAPTCHA token:", recaptchaToken);
    return recaptchaToken;
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdKfxgjAAAAAPO0jJTu6rbM0q8X8agpHUjBDsiA">
      <Helmet>
        <title>Contact Us - Cync Software®</title>
        <meta
          name="description"
          content="Contact us for expert guidance and innovative solutions in lending technology and automation.   Phone +1-855-367-2962 | Email: info@cyncsoftware.com"
        />
      </Helmet>

      <div>
        <motion.div
          className="Productright"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <section className="contact-sections">
            <Container className="h-100">
              <Row className="h-100 align-items-center">
                <Col lg={9}>
                  <div className="content">
                    <motion.div
                      className="Productleft"
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8, ease: "easeOut" }}
                      viewport={{ once: true, amount: 0.7 }}
                    >
                      <h1>Contact Us</h1>
                    </motion.div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </motion.div>
        <div className="space"></div>
        <div className="space"></div>

        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="contact-box">
                <h1>Let's Start a Conversation!</h1>
                <p className="contentpara">
                  Thank you for your interest in Cync Software. To connect with
                  one of our experts, please fill out our webform, send us an
                  email, or give us a call. We are here and eager to assist you.
                </p>
                <br />
                <h2>Sales</h2>
                <p className="contactdetails contentpara">
                  <a
                    href="tel:1-855-367-2962"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +1 855-367-2962, Ext: 1
                  </a>
                </p>
                <p className="contactdetails contentpara">
                  <a
                    href="mailto:sales@cyncsoftware.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    sales@cyncsoftware.com
                  </a>
                </p>
                <br />
                <h2>Support</h2>
                <p className="contactdetails contentpara">
                  <a
                    href="tel:1-855-367-2962"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +1 855-367-2962, Ext: 2
                  </a>
                </p>
                <p className="contactdetails contentpara">
                  <a
                    href="mailto:support@cyncsoftware.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support@cyncsoftware.com
                  </a>
                </p>
                <p style={{ fontSize: "18px" }}>
                  Hours of Operation: 8:30 AM to 8:30 PM (Mon-Fri)
                </p>
                <br />
                <h2>Information</h2>
                <p className="contactdetails contentpara">
                  <a
                    href="tel:1-855-367-2962"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +1 855-367-2962
                  </a>
                </p>
                <p className="contactdetails contentpara">
                  <a
                    href="mailto:info@cyncsoftware.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@cyncsoftware.com
                  </a>
                </p>
                <br />
                <h2>Meet Us in Person</h2>
                <p className="contactdetails contentpara">
                  3505 East Frontage Road, Suite 160, Tampa, FL 33607
                </p>
                <br />
              </div>
            </div>

            <div className="col-md-5 form-container">
              {formSubmitted ? (
                <div className="thank-you-message">
                  <h2>Thank You!</h2>
                  <p>
                    Your message has been sent successfully. We will get back to
                    you soon.
                  </p>
                </div>
              ) : (
                <>
                  <h2>Get in Touch</h2>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      isValid,
                      dirty,
                    }) => (
                      <Form
                        onSubmit={handleSubmit}
                        className="contact-form-container"
                      >
                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="firstName">
                              <Field
                                className="form-control contact-input"
                                innerRef={firstInputRef}
                                type="text"
                                name="firstName"
                                placeholder="First Name*"
                                value={values.firstName}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="lastName">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="lastName"
                                placeholder="Last Name*"
                                value={values.lastName}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="company">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="company"
                                placeholder="Company Name*"
                                value={values.company}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="company"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="jobTitle">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="jobTitle"
                                placeholder="Job Title*"
                                value={values.jobTitle}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="jobTitle"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="email">
                              <Field
                                className="form-control contact-input"
                                type="email"
                                name="email"
                                placeholder="Business Email*"
                                value={values.email}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="phone">
                              <Field
                                className="form-control contact-input"
                                type="tel"
                                name="phone"
                                placeholder="Phone Number (Optional)"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group
                          controlId="inquiry"
                          className="contact-rows"
                          style={{ position: "relative" }}
                        >
                          <Field
                            className="form-control contact-input"
                            as="select"
                            name="inquiry"
                            value={values.inquiry}
                            onChange={handleChange}
                            required
                            style={{ appearance: "none", paddingRight: "30px" }} // Ensure enough space for the icon
                          >
                            <option value="">Inquiry Type*</option>
                            <option value="Sales Inquiry">Sales Inquiry</option>
                            <option value="Product Information">
                              Product Information
                            </option>
                            <option value="Partnership Inquiry">
                              Partnership Inquiry
                            </option>
                            <option value="Support Request">
                              Support Request
                            </option>
                            <option value="Human Relations">
                              Human Relations
                            </option>
                            <option value="Public Relations">
                              Public Relations
                            </option>
                            <option value="General Inquiry">
                              General Inquiry
                            </option>
                          </Field>
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="custom-select-icon"
                          />
                          <ErrorMessage
                            name="inquiry"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="message"
                          className="contact-rows"
                        >
                          <Field
                            className="form-control contact-input"
                            as="textarea"
                            rows={3}
                            name="message"
                            placeholder="Message"
                            value={values.message}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>

                        <Form.Group controlId="agree" className="contact-rows">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            name="agree"
                            checked={values.agree}
                            onChange={handleChange}
                            required
                          />
                          <label
                            className="form-check-label"
                            style={{
                              marginLeft: "25px",
                              top: "-24px",
                              position: "relative",
                            }}
                          >
                            I confirm that I have read and agree to{" "}
                            <a href="/privacy-policy" target="_blank">
                              Cync Software's Privacy Policy
                            </a>{" "}
                            and consent to the sharing of my information.
                          </label>
                          <ErrorMessage
                            name="agree"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>

                        <Button
                          className="formbtn"
                          variant="primary"
                          type="submit"
                          disabled={!(dirty && isValid)}
                          style={{ width: "100%" }}
                        >
                          Submit
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="space"></div>
        <div className="space"></div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default ContactForm;
